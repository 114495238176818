export const Features = [
  {
    image: 'https://www.mlytics.com/wp-content/uploads/2021/08/icon_Low-effort.png',
    title: 'ddosProtection.section3.card1.title',
    content: 'ddosProtection.section3.card1.content'
  },
  {
    image: 'https://www.mlytics.com/wp-content/uploads/2021/08/icon_Unmetered.png',
    title: 'ddosProtection.section3.card2.title',
    content: 'ddosProtection.section3.card2.content'
  },
  {
    image: 'https://www.mlytics.com/wp-content/uploads/2021/08/icon_Comprehensive.png',
    title: 'ddosProtection.section3.card3.title',
    content: 'ddosProtection.section3.card3.content'
  }
]
